import { getLodop } from "@/plugins/Lodop.js";
import { Message } from 'element-ui'

/**
 * 将时间转换为 yyyy-mm-dd hh:mm:ss
 * @param {*} date 
 */
export function getCurDate(date) {  
  let Y = date.getFullYear()
  const M = (date.getMonth() + 1).toString().padStart(2, '0')
  const D = (date.getDate()).toString().padStart(2, '0')
  const h = (date.getHours()).toString().padStart(2, '0')
  const m = (date.getMinutes()).toString().padStart(2, '0')
  const s = (date.getSeconds()).toString().padStart(2, '0')
  return Y+"-"+M+"-"+D+" "+h+":"+m+":"+s;
}

/**
 * 将订单信息放入快递单模板中
 * @param {*} order_info 
 * @param {*} consigner_info 
 * @param {*} lodop_template 
 * @returns
 */
export function getRealExpressTemplate( order_info, consigner_info, lodop_template ){
  try {
    var lodopTemplate = lodop_template;
    var orderInfo = order_info;
    var consignerInfo = consigner_info;
    if (lodopTemplate.search('发件人') != -1) {
      lodopTemplate = lodopTemplate.replace('发件人', consignerInfo.name);
    }
    if (lodopTemplate.search('发件人电话') != -1) {
      lodopTemplate = lodopTemplate.replace('发件人电话', consignerInfo.phone);
    }
    if (lodopTemplate.search('发件地址') != -1) {
      lodopTemplate = lodopTemplate.replace('发件地址', consignerInfo.province+consignerInfo.city+consignerInfo.district+consignerInfo.address);
    }
    if (lodopTemplate.search('发货内容') != -1) {
      let orderSubInfo = '';
      for( let item of orderInfo.order_sub ){
        if( item.sku_short_name != '' ){
          orderSubInfo = orderSubInfo + item.sku_short_name + ' ' + item.quantity + '  ';
        }
        else if( item.baby_short_name != '' ){
          orderSubInfo = orderSubInfo + item.baby_short_name + ' ' + item.quantity + '  ';
        }
        else {
          orderSubInfo = orderSubInfo + item.name + ' ' + item.quantity + '  ';
        }
      }
      lodopTemplate = lodopTemplate.replace('发货内容', orderSubInfo);
    }
    if (lodopTemplate.search('数量') != -1) {
      lodopTemplate = lodopTemplate.replace('数量', orderInfo.quantity);
    }
    if (lodopTemplate.search('发件日期') != -1) {
      const nowDate = new Date();
      lodopTemplate = lodopTemplate.replace('发件日期', getCurDate(nowDate));
    }
    if (lodopTemplate.search('收件人') != -1) {
      lodopTemplate = lodopTemplate.replace('收件人', orderInfo.to_full_name);
    }
    if (lodopTemplate.search('收件地址') != -1) {
      lodopTemplate = lodopTemplate.replace('收件地址', orderInfo.to_area);
    }
    if (lodopTemplate.search('收件手机') != -1) {
      lodopTemplate = lodopTemplate.replace('收件手机', orderInfo.to_mobile);
    }
    if (lodopTemplate.search('收件固话') != -1) {
      if(orderInfo.to_phone == ''){
        lodopTemplate = lodopTemplate.replace('，收件固话', '');
      }
      else {
        lodopTemplate = lodopTemplate.replace('收件固话', orderInfo.to_phone);
      }
    }
    if (lodopTemplate.search('目的地') != -1) {
      lodopTemplate = lodopTemplate.replace('目的地', orderInfo.to_area);
    }
    if (lodopTemplate.search('买家旺旺') != -1) {
      lodopTemplate = lodopTemplate.replace('买家旺旺', orderInfo.buyer_login_id);
    }
    return lodopTemplate;
  } catch( err ){
    return Promise.reject(new Error(err))
  }
}

/**
 * 将订单信息放入发货单模板中
 * @param {*} order_info 
 * @param {*} consigner_info 
 * @param {*} lodop_template 
 * @returns 
 */
export function getRealFahuoTemplate( order_info, consigner_info, lodop_template ){
  try {
    var lodopTemplate = lodop_template;
    var orderInfo = order_info;
    var consignerInfo = consigner_info;
    if (lodopTemplate.search('发货单标题') != -1) {
      lodopTemplate = lodopTemplate.replace('发货单标题', consignerInfo.title);
    }
    if (lodopTemplate.search('店铺名称') != -1) {
      lodopTemplate = lodopTemplate.replace('店铺名称', consignerInfo.shop_name);
    }
    if (lodopTemplate.search('发件日期') != -1) {
      const nowDate = new Date();
      lodopTemplate = lodopTemplate.replace('发件日期', getCurDate(nowDate));
    }
    if (lodopTemplate.search('买家旺旺') != -1) {
      lodopTemplate = lodopTemplate.replace('买家旺旺', orderInfo.buyer_login_id);
    }
    if (lodopTemplate.search('收件人') != -1) {
      lodopTemplate = lodopTemplate.replace('收件人', orderInfo.to_full_name);
    }
    if (lodopTemplate.search('收件手机') != -1) {
      lodopTemplate = lodopTemplate.replace('收件手机', orderInfo.to_mobile);
    }
    if (lodopTemplate.search('收件电话') != -1) {
      if(orderInfo.to_phone == ''){
        lodopTemplate = lodopTemplate.replace('，收件电话', '');
      }
      else {
        lodopTemplate = lodopTemplate.replace('收件电话', orderInfo.to_phone);
      }
      
    }
    if (lodopTemplate.search('收件地址') != -1) {
      lodopTemplate = lodopTemplate.replace('收件地址', orderInfo.to_area);
    }
    if (lodopTemplate.search('买家留言') != -1) {
      lodopTemplate = lodopTemplate.replace('买家留言', orderInfo.buyer_feedback);
    }
    if (lodopTemplate.search('卖家备注') != -1) {
      lodopTemplate = lodopTemplate.replace('卖家备注', orderInfo.seller_memo + '  ' + orderInfo.invoice_content);
    }
    if (lodopTemplate.search('经办人') != -1) {
      lodopTemplate = lodopTemplate.replace('经办人', consignerInfo.operator + ' ');
    }
    if (lodopTemplate.search('发件旺旺') != -1) {
      lodopTemplate = lodopTemplate.replace('发件旺旺', consignerInfo.shop_name);
    }
    if (lodopTemplate.search('联系电话') != -1) {
      lodopTemplate = lodopTemplate.replace('联系电话', consignerInfo.phone);
    }
    if (lodopTemplate.search('(超文本19的HTML代码内容)') != -1) {
      let orderSubInfo = '';
      for( let item of orderInfo.order_sub ){
        if( item.sku_short_name != '' ){
          orderSubInfo = orderSubInfo + item.sku_short_name + ' ' + item.quantity + '  ';
        }
        else if( item.baby_short_name != '' ){
          orderSubInfo = orderSubInfo + item.baby_short_name + ' ' + item.quantity + '  ';
        }
        else {
          orderSubInfo = orderSubInfo + item.name + ' ' + item.quantity + '  ';
        }
      }
      lodopTemplate =  lodopTemplate.replace('(超文本19的HTML代码内容)', orderSubInfo);
    }
    if (lodopTemplate.search('(超文本21的HTML代码内容)') != -1) {
      let orderSubInfo = '';
      for( let item of orderInfo.order_sub ){
        if( item.sku_short_name != '' ){
          orderSubInfo = orderSubInfo + item.sku_short_name + ' ' + item.quantity + '  ';
        }
        else if( item.baby_short_name != '' ){
          orderSubInfo = orderSubInfo + item.baby_short_name + ' ' + item.quantity + '  ';
        }
        else {
          orderSubInfo = orderSubInfo + item.name + ' ' + item.quantity + '  ';
        }
      }
      lodopTemplate = lodopTemplate.replace('(超文本21的HTML代码内容)', orderSubInfo);
    }
    if (lodopTemplate.search('(超文本17的HTML代码内容)') != -1) {
      let orderSubInfo = '';
      for( let item of orderInfo.order_sub ){
        if( item.sku_short_name != '' ){
          orderSubInfo = orderSubInfo + item.sku_short_name + ' ' + item.quantity + '  ';
        }
        else if( item.baby_short_name != '' ){
          orderSubInfo = orderSubInfo + item.baby_short_name + ' ' + item.quantity + '  ';
        }
        else {
          orderSubInfo = orderSubInfo + item.name + ' ' + item.quantity + '  ';
        }
      }
      lodopTemplate = lodopTemplate.replace('(超文本17的HTML代码内容)', orderSubInfo);
    }

    return lodopTemplate;
  } catch( err ){
    return Promise.reject(new Error(err))
  }
}

/**
 * lodop预览方法
 * @param {*} LODOP 
 * @param {*} type 
 * @param {*} iframeId 
 * @param {*} order_info 
 * @param {*} consigner_info 
 * @param {*} image_url 
 * @param {*} lodop_template 
 * @returns 
 */
export function lodopPreview( LODOP, type, iframeId, order_info, consigner_info, image_url, lodop_template) {
  try {
    let realLodopTemplate;
    // 如果打印快递单
    if( type == 1 ){
      realLodopTemplate = getRealExpressTemplate( order_info, consigner_info, lodop_template );
    }
    // 如果打印发货单
    else if( type == 2 ){
      realLodopTemplate = getRealFahuoTemplate( order_info, consigner_info, lodop_template );
    }
    // 如果只预览模板
    else if( type == 3 ){
      realLodopTemplate = lodop_template;  
    }
    else if( type == 4 ){
      if (lodop_template.search('(超文本19的HTML代码内容)') != -1) {
        realLodopTemplate = lodop_template.replace('(超文本19的HTML代码内容)', '');
      }
      else if (lodop_template.search('(超文本21的HTML代码内容)') != -1) {
        realLodopTemplate = lodop_template.replace('(超文本21的HTML代码内容)', '');
      }
      else if (lodop_template.search('(超文本17的HTML代码内容)') != -1) {
        realLodopTemplate = lodop_template.replace('(超文本17的HTML代码内容)', '');
      }
      else {
        realLodopTemplate = lodop_template;
      }
      
    }
    else if( type == 5 ){
      realLodopTemplate = LODOP.ADD_PRINT_HTM(20,20,"100%","100%",CreateHTMLTemplate({order_info_list:{},consigner_info}));
    }
    else {
      return Promise.reject(new Error('错误打印类型'));
    }

    if ((typeof realLodopTemplate != 'undefined' && realLodopTemplate != '')){
      eval(realLodopTemplate);

      // 设置lodop预览参数
      LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
      if( type == 1 || type == 3){
        LODOP.ADD_PRINT_SETUP_BKIMG(`<img border='0' src='${image_url}'>`);
      }
      LODOP.SET_SHOW_MODE("PREVIEW_IN_BROWSE",1);
      LODOP.SET_SHOW_MODE('BKIMG_PRINT',1);
      LODOP.SET_SHOW_MODE('HIDE_PBUTTIN_PREVIEW', 1);
      LODOP.SET_PREVIEW_WINDOW(0, 3, 0, 800, 600, '');
      // 预览到指定容器中
      LODOP.PREVIEW(iframeId);
    } 
  } catch( err ) {
    return Promise.reject(new Error(err));
  }
 
}

/**
 * lodop单个打印方法
 * @param {*} LODOP 
 * @param {*} type 
 * @param {*} printer 
 * @param {*} print_num 
 * @param {*} order_info 
 * @param {*} consigner_info 
 * @param {*} image_url 
 * @param {*} lodop_template 
 * @returns 
 */
export function lodopSinglePrint(LODOP, type, printer, print_num, order_info, consigner_info, image_url, lodop_template) {
  try {
    let realLodopTemplate  = LODOP.ADD_PRINT_HTM(20,20,"100%","100%",CreateHTMLTemplate({order_info_list:order_info,consigner_info}));

    if (typeof realLodopTemplate != 'undefined' && realLodopTemplate != ''){
      eval(realLodopTemplate);
      // 设置lodop预览参数
      // 宽度溢出缩放
      LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
      if( type == 1 ){
        LODOP.ADD_PRINT_SETUP_BKIMG(`<img border='0' src='${image_url}'>`);
      }
      LODOP.SET_SHOW_MODE("PREVIEW_IN_BROWSE",1);
      // 打印不包含背景图
      LODOP.SET_SHOW_MODE('BKIMG_PRINT',0);
      // 设置选择的打印机
      LODOP.SET_PRINTER_INDEXA( printer );
      LODOP.SET_PRINT_COPIES( print_num );
      // 弹出打印确认框
      LODOP.PRINT();
      return Promise.resolve();
    }
    else {
      return Promise.reject(new Error('获取真实模板失败'));
    }

  } catch( err ) {
    return Promise.reject(new Error(err))
  }
}

/**
 * lodop打印弹窗方法
 * @param {*} LODOP 
 * @param {*} type 
 * @param {*} order_info 
 * @param {*} consigner_info 
 * @param {*} image_url 
 * @param {*} lodop_template 
 * @returns 
 */
export function lodopSinglePrinta(LODOP, type, order_info, consigner_info, image_url = null, lodop_template = null) {
  try {
    let realLodopTemplate;
    // 如果打印快递单
    if( type == 1 ){
      realLodopTemplate = getRealExpressTemplate( order_info, consigner_info, lodop_template );
    }
    // 如果打印发货单
    else if( type == 2 ){
      realLodopTemplate = getRealFahuoTemplate( order_info, consigner_info, lodop_template );
    }
    else {
      return Promise.reject(new Error('错误打印类型'));
    }
    if (typeof realLodopTemplate != 'undefined' && realLodopTemplate != ''){
      eval(realLodopTemplate);
      // 设置lodop预览参数
      // 宽度溢出缩放
      LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
      if( type == 1 ){
        LODOP.ADD_PRINT_SETUP_BKIMG(`<img border='0' src='${image_url}'>`);
      }
      LODOP.SET_SHOW_MODE("PREVIEW_IN_BROWSE",1);
      // 打印包含背景图
      LODOP.SET_SHOW_MODE('BKIMG_PRINT',0);
      // 弹出打印确认框
      LODOP.PRINTA();
      return Promise.resolve();
    }
    else {
      return Promise.reject(new Error('获取真实模板失败'));
    }
  } catch( err ) {
    return Promise.reject(new Error(err))
  }
}


/**
 * lodop批量打印方法
 * @param {*} LODOP 
 * @param {*} type 
 * @param {*} printer 
 * @param {*} print_num 
 * @param {*} order_info_list 
 * @param {*} consigner_info 
 * @param {*} image_url 
 * @param {*} lodop_template 
 * @returns 
 */
export function lodopBatchPrint(LODOP, type, printer, print_num, order_info_list, consigner_info, image_url = null, lodop_template = null) {
  try {
    // 如果打印快递单
    if( type == 1 ){
      for (let item of order_info_list) {
        let realLodopTemplate;
        realLodopTemplate = getRealExpressTemplate( item, consigner_info, lodop_template );
        if (typeof realLodopTemplate != 'undefined' && realLodopTemplate != ''){
          eval(realLodopTemplate);
          // 设置lodop预览参数
          // 宽度溢出缩放
          LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
          // 设置背景图
          LODOP.ADD_PRINT_SETUP_BKIMG(`<img border='0' src='${image_url}'>`);
          LODOP.SET_SHOW_MODE("PREVIEW_IN_BROWSE",1);
          // 打印不包含背景图
          LODOP.SET_SHOW_MODE('BKIMG_PRINT',0);
          // 设置选择的打印机
          LODOP.SET_PRINTER_INDEXA( printer );
          LODOP.SET_PRINT_COPIES( print_num );
          // 弹出打印确认框
          LODOP.PRINT();        
        }
        else {
          return Promise.reject(new Error('获取真实模板失败'));
        }
      }
      return Promise.resolve();
    }
    // 如果打印发货单
    else if( type == 2 ){
      for (let item of order_info_list) {
        let realLodopTemplate  = LODOP.ADD_PRINT_HTM(20,20,"100%","100%",CreateHTMLTemplate({order_info_list:item,consigner_info}));
        if (typeof realLodopTemplate != 'undefined' && realLodopTemplate != ''){
          eval(realLodopTemplate);
          // 设置lodop预览参数
          // 宽度溢出缩放
          LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
          LODOP.SET_SHOW_MODE("PREVIEW_IN_BROWSE",1);
          // 打印不包含背景图
          LODOP.SET_SHOW_MODE('BKIMG_PRINT',0);
          // 设置选择的打印机
          LODOP.SET_PRINTER_INDEXA( printer );
          LODOP.SET_PRINT_COPIES( print_num );
          // 弹出打印确认框
          LODOP.PRINT();
        }
        else {
          return Promise.reject(new Error('获取真实模板失败'));
        }
      }
      return Promise.resolve();
    }
    else {
      return Promise.reject(new Error('错误打印类型'));
    }
  } catch( err ) {
    return Promise.reject(new Error(err))
  }
}

/**
 * lodop备货单打印方法
 * @param {*} LODOP 
 * @param {*} printer 
 * @param {*} print_num 
 * @param {*} stock_list_info 
 * @param {*} lodop_template 
 * @returns 
 */
export function lodopStocklistPrint(LODOP, printer, print_num, stock_list_info, lodop_template) {
  try {
    let realLodopTemplate;
    realLodopTemplate = lodop_template;

    if (typeof realLodopTemplate != 'undefined' && realLodopTemplate != ''){
      eval(realLodopTemplate);
      // 设置lodop预览参数
      // 宽度溢出缩放
      LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
      LODOP.SET_SHOW_MODE("PREVIEW_IN_BROWSE",1);
      // 打印不包含背景图
      LODOP.SET_SHOW_MODE('BKIMG_PRINT',0);
      // 设置选择的打印机
      LODOP.SET_PRINTER_INDEXA( printer );
      LODOP.SET_PRINT_COPIES( print_num );
      // 弹出打印确认框
      LODOP.PRINT();
      return Promise.resolve();
    }
    else {
      return Promise.reject(new Error('获取真实模板失败'));
    }

  } catch( err ) {
    return Promise.reject(new Error(err))
  }
}

// 构造html模板 
// params 订单数据
function CreateHTMLTemplate(params){
  let BuyersData = JSON.parse(JSON.stringify(params.order_info_list))||{}
  let sellerData = JSON.parse(JSON.stringify(params.consigner_info))||{}
  let template = `<div>
      <div style="text-align: center;font-size: 32px;margin-bottom: 20px;">${sellerData.title||"发货单标题"}</div>
      <table style="width: 100%;margin-bottom: 20px;">
        <tr >
          <td style="display: inline-block;width: 200px;">发货日期：${BuyersData.delivered_time|| ""}</td>
          <td style="display: inline-block;width: 200px;"></td>
        </tr>
      </table>
      <div style="margin-bottom: 20px;">
        <div style="display: inline-block;">地址： ${(BuyersData.province + BuyersData.city + BuyersData.area )||"XX省XX市XX区 地址信息"}</div>
      </div>
      <table border="1" bordercolor="#000000" style="width: 100%; box-sizing: border-box; margin-bottom: 20px;border-collapse:collapse;border:solid 1px;">
      <tr><td>序号</td><td>宝贝名称</td><td>规格</td><td>数量</td></tr>`
    let sub_quantity = 0;
    let sub_item_amount = 0;
    if(Array.isArray(BuyersData.order_sub)){
      let sub_index = 0;
      BuyersData.order_sub.map((item,index)=>{
        if(!item.refundStatusStr){
          sub_index += 1;
          sub_quantity += parseFloat( item.quantity);
          sub_item_amount += parseFloat( item.item_amount);
          template += `
              <tr><td>${sub_index}</td><td>${item.product_name}</td><td>
            `
          if(Array.isArray(item.sku_info)){
            item.sku_info.map((sku,sku_index)=>{
              template += `${sku_index!=0?"，":""}${sku.name||"规格名称"}：${sku.value||""}`
            })
          }
          template += `</td><td>${item.quantity}</td></tr>`
        }
      })
    }
    template += `
      </table>
      <div style="margin-bottom: 10px;">数量：${sub_quantity} 实付金额：${sub_item_amount||"0.00"}</div>
      <div style="display: flex;margin-bottom: 10px;">
        <div style="margin-right: 30px;">经办人：${sellerData.operator||""}</div>
        <div style="margin-right: 30px;">联系方式：${sellerData.phone||"联系电话"}</div>
      </div>
      <div>友情提示：${sellerData.tips||""}</div>
    </div>`
  
  return template
}

/**
 * code换取token
 * @param {string} code 
 * @returns {Object}
 */
 export function catchToken(code){
  return new Promise((resolve,reject) => {
    code2token({"code":code}).then(res => {
      try{
        const {data} = res
        window.localStorage.setItem("x_token",data.token)
        resolve(data.token)
      }catch{
        reject()
      }
    }).catch(err => {
      reject()
    })
  })
}

/**
 * 解析url
 * @param {string} url 
 */
export function getUrlParamByKey(key,url){
  var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
  if(url.split("?")[1]){
    var r = url.split("?")[1].match(reg)
    if(r != null){
      return unescape(r[2]);
    }
  }
  return null
}
